import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaWpp from "../../components/CTAWpp";

import logo_topo from "../../assets/logo_topo.png";
import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";
import header3_4 from "../../assets/header3_4.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: header3_1,
  },
  {
    id: 2,
    image: header3_2,
  },
  {
    id: 3,
    image: header3_3,
  },
  {
    id: 4,
    image: header3_4,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <figure className="top">
            <img src={logo_topo} alt="Logo" />
          </figure>

          <h1 className="title">
            Pisos Vinílico, Laminados, Rodapés e muito mais para a decoração do
            seu lar!
          </h1>

          <div className="cta">
            <p className="text">Solicite um orçamento pelo WhatsApp:</p>

            <CtaWpp />
          </div>
        </article>
      </section>

      <section className="mobile">
        <article className="container">
          <h1 className="title">
            Pisos Vinílico, Laminados, Rodapés e muito mais para a decoração do
            seu lar!
          </h1>

          <div className="cta">
            <p className="text">Solicite um orçamento pelo WhatsApp:</p>

            <CtaWpp />
          </div>
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">
            Aqui você encontra pisos e acabamentos ideais para sua decoração.
          </h2>
        </article>
      </section>

      <section className="header3">
        <article className="container vertical">
          <h2 className="title">Nossos produtos</h2>

          <p className="text">
            Trabalhamos com as melhores marcas de pisos vinílicos e laminados ,
            rodapés, painéis ripados e outros complementos para a decoração da
            sua casa!
          </p>

          {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) ? (
            <div className="mobile">
              <figure>
                <img src={header3_1} alt="Pisos Laminados" />
              </figure>

              <figure>
                <img src={header3_2} alt="Painéis Ripados" />
              </figure>

              <figure>
                <img src={header3_3} alt="Pisos Vinílicos" />
              </figure>

              <figure>
                <img src={header3_4} alt="Rodapé de Poliestireno e MDF" />
              </figure>
            </div>
          ) : (
            <Swiper
              className="carousel"
              spaceBetween={20}
              slidesPerView={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? 1
                  : 4
              }
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}

          <div className="cta">
            <p className="text">
              Faça agora uma cotação gratuita pelo WhatsApp:
            </p>

            <CtaWpp />
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
