import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about2 from "../../assets/about2.png";
import about2Mobile from "../../assets/mobile/about2.png";
import about3 from "../../assets/about3.png";
import about3Mobile from "../../assets/mobile/about3.png";
import about4 from "../../assets/about4.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">Nosso diferencial</h2>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={about1_1} alt="Praticidade" />
              </figure>

              <p className="item_title">Praticidade</p>

              <p className="item_text">Produtos com garantia e qualidade.</p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_2} alt="Melhor Preço" />
              </figure>

              <p className="item_title">Melhor Preço</p>

              <p className="item_text">
                PParcele em até 12x no cartão de crédito ou ganhe desconto
                pagando à vista.
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_3} alt="Entrega Rápida" />
              </figure>

              <p className="item_title">Entrega Rápida</p>

              <p className="item_text">
                Entrega rápida em todo Rio de Janeiro e São Paulo.
              </p>
            </div>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="cta">
            <p className="text">Solicite um orçamento pelo WhatsApp:</p>

            <CtaWpp />
          </div>

          <figure className="right">
            <img
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? about2Mobile
                  : about2
              }
              alt="Mulher sentada no chão de casa"
            />
          </figure>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <figure>
            <img
              src={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? about3Mobile
                  : about3
              }
              alt="Finottato - Ruffino - Sika - Eucafloor - Quick-Step"
            />
          </figure>
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <h2 className="title">
            <span>FORMAS DE </span> PAGAMENTO
          </h2>

          <p className="text">
            crédito em até 12x, débito, PIX e <br /> transferência bancária com
            desconto
          </p>

          <figure>
            <img src={about4} alt="FORMAS DE PAGAMENTO" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
