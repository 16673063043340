import React from "react";
import "./styles.css";

import logo_topo from "../../assets/logo_topo.png";

const Menu = () => {
  return (
    <>
      <section id="mobile">
        <div className="container">
          <figure className="top">
            <img src={logo_topo} alt="Logo" />
          </figure>
        </div>
      </section>

      <nav id="menu">
        <section className="container">
          <p className="text">
            Parcelamos em <span> até 12x </span> sem juros nos cartões!
          </p>
        </section>
      </nav>
    </>
  );
};

export default Menu;
